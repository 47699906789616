import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import thunk, { ThunkMiddleware } from 'redux-thunk'
// import thunkMiddleware from 'redux-thunk'

import rootReducer from './reducer'

// const middleware = [thunk as ThunkMiddleware, logger]

export const store = configureStore({
    reducer: rootReducer,
    // middleware,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>

export default store
