import { Spin, Card, Select, Radio, RadioChangeEvent } from 'antd'
import ReactECharts from 'echarts-for-react'
import { useEffect, useState } from 'react'
import InsightsService from '../services/insightsService'

export const TrendContainer: React.FC = () => {
    const [functions, setFunctions]: any = useState([])
    const [loading, setLoading]: any = useState(true)
    const [lines, setLines]: any = useState([])
    const [attribute, setAttribute]: any = useState('totalCPU')

    useEffect(() => {
        handleChange(['java', 'python', 'node', 'runtime.goexit.abi0'])
        InsightsService.getInsights('/processor/functions').then(
            (data: any) => {
                if (data.data.hotspotFunctions) {
                    setFunctions(
                        data.data.hotspotFunctions
                            .map((e: any) => e.function)
                            .sort()
                            .map((e: any) => {
                                return { name: e, value: e }
                            })
                    )
                }
            }
        )
    }, [])

    const handleChange = async (value: string[]) => {
        setLoading(true)
        let newlines: any = []
        let funcpromises = []

        // make all API calls
        for (const func of value) {
            const params = new URLSearchParams()
            params.append('function', func)
            funcpromises.push(
                InsightsService.getInsights(
                    '/processor/functions/trend?' + params.toString()
                )
            )
        }

        // process all data
        await Promise.all(funcpromises).then((funcs: any) => {
            for (const f of funcs) {
                newlines.push([
                    f.data.function,
                    f.data.trend.sort((a: any, b: any) => {
                        if (a.date < b.date) {
                            return -1
                        }
                        if (a.date > b.date) {
                            return 1
                        }
                        return 0
                    }),
                ])
            }
        })
        setLines(newlines)
        setLoading(false)
    }
    return (
        <>
            <Card
                title="Trends"
                style={{
                    borderRadius: '20px',
                    margin: '.5rem 1rem 1rem 1rem',
                }}
                bodyStyle={{ padding: 0, textAlign: 'center' }}
            >
                <Spin spinning={Object.keys(functions).length == 0}>
                    <Select
                        mode="multiple"
                        disabled={Object.keys(functions).length == 0}
                        style={{ width: '100%' }}
                        placeholder="Please select a symbol"
                        defaultValue={[
                            'java',
                            'python',
                            'node',
                            'runtime.goexit.abi0',
                        ]}
                        onChange={handleChange}
                        options={functions}
                    />
                </Spin>
                <Radio.Group
                    value={attribute}
                    onChange={(e: RadioChangeEvent) => {
                        setAttribute(e.target.value)
                    }}
                    optionType="button"
                >
                    <Radio value={'totalCPU'}>totalCPU</Radio>
                    <Radio value={'selfCPU'}>selfCPU</Radio>
                    <Radio value={'services'}>services</Radio>
                </Radio.Group>
                <Spin spinning={loading} tip="Fetching trend data">
                    <ReactECharts
                        style={{ height: '25rem' }}
                        notMerge={true}
                        option={{
                            tooltip: {
                                trigger: 'axis',
                            },
                            xAxis: {
                                type: 'time',
                            },
                            yAxis: {
                                type: 'value',
                            },
                            dataset:
                                lines.length > 0
                                    ? lines.map((l: any) => {
                                          return { source: l[1] }
                                      })
                                    : {},
                            series:
                                lines.length > 0
                                    ? lines.map((l: any, i: any) => {
                                          return {
                                              name: l[0],
                                              datasetIndex: i,
                                              encode: {
                                                  x: 'date',
                                                  y: attribute,
                                              },
                                              type: 'line',
                                              smooth: true,
                                              showSymbol: false,
                                          }
                                      })
                                    : {},
                        }}
                    />
                </Spin>
            </Card>
        </>
    )
}
