import React, { FunctionComponent, useState } from 'react'
import { Button, Divider, List, Modal, Spin } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
export interface ModalTraceProps {
    open: boolean
    setOpen: any
    data: any
}
export const ModalTrace: FunctionComponent<ModalTraceProps> = ({
    open,
    setOpen,
    data,
}: ModalTraceProps) => {
    const [loading, setLoading] = useState(false)
    const handleDownload = () => {
        setLoading(true)

        const content = data.functionPaths.map((paths: any) => {
            return (
                paths.process +
                '\n' +
                paths.path.map((path: string) => path).join('\n') +
                '\n\n'
            )
        })

        let blob = new Blob(['\uFEFF' + content], {
            type: 'text/plain;charset=utf-18',
        })

        let url = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.href = url

        link.setAttribute('download', data.functionName + '.txt')

        document.body.appendChild(link)
        link.click()
        link.remove()

        setTimeout(() => {
            setLoading(false)
            //setOpen(false)
        }, 3000)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Modal
                open={open}
                title={data.functionName}
                onOk={handleDownload}
                onCancel={handleClose}
                footer={[
                    <Button key="back" onClick={handleClose}>
                        Return
                    </Button>,
                ]}
                width={1200}
            >
                {data.functionPaths && (
                    <div
                        style={{
                            justifyContent: 'flex-end',
                            display: 'flex',
                        }}
                    >
                        {' '}
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<DownloadOutlined />}
                            size={'small'}
                            loading={loading}
                            onClick={handleDownload}
                            disabled={data.functionPaths == undefined}
                        ></Button>
                    </div>
                )}

                {data.functionPaths &&
                    data.functionPaths.map((paths: any) => {
                        return (
                            <>
                                <Divider orientation="left">
                                    {`Process: ${paths.process}, AppID: ${paths.appId}, Count: ${paths.count}`}
                                </Divider>
                                <List
                                    bordered
                                    dataSource={paths.path}
                                    renderItem={(item: string) => (
                                        <List.Item
                                            style={{ wordBreak: 'break-all' }}
                                        >
                                            {item}
                                        </List.Item>
                                    )}
                                />
                            </>
                        )
                    })}
                <Spin spinning={data.functionPaths == undefined} />
            </Modal>
        </>
    )
}
