import axios from 'axios'
import amplify_conf from '../../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
Amplify.configure(amplify_conf)

const authClient = axios.create({
    // baseURL: serviceConfig.authServiceURL,
})

authClient.interceptors.request.use(
    async (config: any) => {
        // Gets current user session token after cognito sign in.
        await Auth.currentSession()
            .then((res) => {
                let accessToken = res.getAccessToken()
                const access_token = accessToken.getJwtToken()
                if (access_token) {
                    config.headers['Authorization'] = 'Bearer ' + access_token
                }
            })
            .catch((err) => Auth.federatedSignIn())
        return config
    },
    (error: any) => {
        return Promise.reject(error)
    }
)

export default authClient
