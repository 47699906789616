import {
    COGNITO_USER_POOL_ID,
    COGNITO_CLIENT_ID,
    APP_DOMAIN,
    COGNITO_REGION,
    COGNITO_LOGIN_URL,
} from '../../config/service'
import { Hub } from 'aws-amplify'

const port = window.location.port
let formattedport =
    port === undefined || port === null || port.trim() === '' ? '' : ':' + port
let location_callback = `${window.location.protocol}//${window.location.hostname}${formattedport}/login/callback`
let location_logout = `${window.location.protocol}//${window.location.hostname}${formattedport}`

const amplify_conf = {
    Auth: {
        region: COGNITO_REGION,
        userPoolId: COGNITO_USER_POOL_ID,
        userPoolWebClientId: COGNITO_CLIENT_ID,
        mandatorySignIn: false,
        cookieStorage: {
            domain: APP_DOMAIN,
            path: '/',
            secure: true,
        },
        oauth: {
            domain: COGNITO_LOGIN_URL,
            scope: ['email', 'profile', 'openid'],
            redirectSignIn: location_callback,
            redirectSignOut: location_logout,
            responseType: 'token',
        },
    },
}

const listener = (data: { payload?: any }) => {
    switch (data.payload.event) {
        case 'signIn':
        case 'cognitoHostedUI':
            console.log('Authenticated...')
            break
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
            console.log('Error', data)
            break
    }
}

Hub.listen('auth', listener)

export default amplify_conf
