import { FunctionComponent, useEffect, useState } from 'react'
import amplify_conf from '../services/auth/amplify'
import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
Amplify.configure(amplify_conf)

export interface IUserPanel {}

export const UserPanel: FunctionComponent<IUserPanel> = ({}: IUserPanel) => {
    const [userLoginName, setUserLoginName] = useState('')

    useEffect(() => {
        Auth.currentSession().then((res) => {
            setUserLoginName(res.getIdToken().payload.email)
        })
    }, [])

    return <>{userLoginName}</>
}

export default UserPanel
