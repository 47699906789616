import { combineReducers } from '@reduxjs/toolkit'
import { authLogin } from './authLogin.slice'
import { insights, functions, functionTrace } from './insights.slice'

const rootReducer = combineReducers({
    authLogin: authLogin,
    insights: insights,
    functions: functions,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
