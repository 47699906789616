import { SearchOutlined, RedoOutlined, EyeOutlined } from '@ant-design/icons'
import {
    Spin,
    Table,
    Card,
    Row,
    Col,
    Tooltip,
    InputRef,
    Input,
    Switch,
    message,
    Button,
    DatePicker,
    Space,
    Modal,
} from 'antd'
import ReactECharts from 'echarts-for-react'
import type { ColumnsType } from 'antd/es/table'
import { useEffect, useRef, useState } from 'react'
import InsightsService from '../services/insightsService'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import minMax from 'dayjs/plugin/minMax'
import { ModalTrace } from './modalTraceContainer'
const { RangePicker } = DatePicker

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)
dayjs.extend(minMax)

export const HotspotContainer: React.FC = () => {
    interface DataType {
        function: string
        totalCPU: number
        selfCPU: number
        services: number
        customers: number
    }

    const tooltip = {
        trigger: 'item',
        valueFormatter: (num: Number) => num.toFixed(2) + '%',
    }
    const base = {
        itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
        },
    }

    const [regex, setRegex] = useState(false)
    const [functions, setFunctions]: any = useState([])
    const [all, setAll] = useState([])
    const [languages, setLanguages]: any = useState({})
    const [arch, setArch]: any = useState({})
    const [dates, setDates]: any = useState([
        dayjs().subtract(2, 'day').endOf('day'),
        dayjs().subtract(1, 'day').endOf('day'),
    ])
    const [functionTrace, setFunctionTrace]: any = useState({})
    const [open, setOpen] = useState(false)

    const searchInput = useRef<InputRef>(null)

    useEffect(() => {
        if (dates[0] && dates[1]) {
            const params = new URLSearchParams()
            params.append('start', dates[0].format())
            params.append('stop', dates[1].format())
            InsightsService.getInsights(
                '/processor/functions?' + params.toString()
            ).then((data: any) => {
                if (data.data.hotspotFunctions) {
                    let hotspot_map: any = {}
                    let count = 0
                    data.data.hotspotFunctions
                        .filter((e: any) => e.totalCPU > 0.00005)
                        .forEach((e: any) => {
                            if (!hotspot_map.hasOwnProperty(e.function)) {
                                hotspot_map[e.function] = e
                            } else {
                                hotspot_map[e.function].totalCPU += e.totalCPU
                                hotspot_map[e.function].services += e.services
                                hotspot_map[e.function].selfCPU += e.selfCPU
                                hotspot_map[e.function].customers += e.customers
                            }
                            if (e.function == 'java') {
                                count += 1
                            }
                        })
                    setFunctions(
                        Object.values(hotspot_map).map((e: any) => {
                            return {
                                function: e.function,
                                totalCPU: e.totalCPU / count,
                                selfCPU: e.selfCPU / count,
                                services: e.services / count,
                                customers: e.customers / count,
                            }
                        })
                    )
                    setAll(data.data.hotspotFunctions)
                }
            })
            InsightsService.getInsights(
                '/processor/architectures?' + params.toString()
            ).then((data: any) => {
                setArch({
                    tooltip,
                    series: [
                        {
                            ...base,
                            type: 'pie',
                            radius: ['40%', '70%'],
                            data: data.data.hotspotArchitectures
                                .filter(
                                    (e: any) =>
                                        e.architecture != 'unknown' &&
                                        !e.architecture.includes('error:')
                                )
                                .map((e: any) => {
                                    return {
                                        name: e.architecture,
                                        value: e.totalCPU,
                                    }
                                }),
                        },
                    ],
                })
            })
            InsightsService.getInsights(
                '/processor/languages?' + params.toString()
            ).then((data: any) => {
                let langs: any = {
                    python: { total: 0, versions: [] },
                    java: { total: 0, versions: [] },
                    golang: { total: 0, versions: [] },
                    node: { total: 0, versions: [] },
                    ruby: { total: 0, versions: [] },
                }
                for (const v of data.data.hotspotLanguages) {
                    if (
                        !langs[v.language].versions.hasOwnProperty(v.language)
                    ) {
                        langs[v.language].versions[v.language] = []
                    }
                    langs[v.language].versions.push(v)
                    langs[v.language].total += v.totalCPU
                }
                setLanguages({
                    tooltip,
                    series: [
                        {
                            ...base,
                            type: 'sunburst',
                            label: {
                                formatter: (param: any) => {
                                    const depth = param.treePathInfo.length
                                    if (depth === 2) {
                                        return param.data.name
                                    }
                                    return ' '
                                },
                            },
                            nodeClick: false,
                            radius: ['20%', '100%'],
                            data: [
                                ...Object.entries(langs).map((e: any) => {
                                    const subvers = e[1].versions
                                        .filter((a: any) => a.totalCPU > 0.8)
                                        .sort(
                                            (a: any, b: any) =>
                                                b.totalCPU - a.totalCPU
                                        )

                                    return {
                                        name: e[0],
                                        value: e[1].total,
                                        children: [
                                            ...subvers.map((v: any) => {
                                                return {
                                                    name: v.version.split(
                                                        '\n'
                                                    )[0],
                                                    value: v.totalCPU,
                                                }
                                            }),
                                        ],
                                    }
                                }),
                            ],
                        },
                    ],
                })
            })
        }
    }, [dates])

    const filterServices = (func: any) => {
        setFunctions([])
        const params = new URLSearchParams()
        params.append('include', func)
        params.append('start', dates[0].format())
        params.append('stop', dates[1].format())
        InsightsService.getInsights(
            '/processor/functions/lookout?' + params.toString()
        ).then((data: any) => {
            setFunctions(data.data.hotspotFunctions)
        })
    }

    const getFunctionTrace = async (func: any) => {
        setFunctionTrace({})
        const params = new URLSearchParams()
        params.append('function', func)
        setOpen(true)
        InsightsService.getFunctionTrace(params.toString()).then(
            (data: any) => {
                setFunctionTrace(data.data)
            }
        )
    }

    const columns: ColumnsType<DataType> = [
        {
            title: (
                <Tooltip title="the name of the function">
                    Function Name
                </Tooltip>
            ),
            ellipsis: true,
            width: '35rem',
            dataIndex: 'function',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
                <div
                    style={{ padding: 8 }}
                    onKeyDown={(e) => e.stopPropagation()}
                >
                    <Input
                        ref={searchInput}
                        placeholder={`Function Search`}
                        value={selectedKeys[0]}
                        onChange={(e: any) =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() => {
                            if (regex) {
                                try {
                                    new RegExp(
                                        (
                                            selectedKeys[0] as string
                                        ).toLowerCase()
                                    )
                                } catch (err) {
                                    message.error('Invalid Regex')
                                    return
                                }
                            }
                            confirm()
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Switch
                        onChange={() => setRegex(!regex)}
                        checkedChildren="regex"
                        unCheckedChildren="regex"
                    />
                </div>
            ),
            filterIcon: (filtered: boolean) => (
                <SearchOutlined
                    style={{ color: filtered ? '#1677ff' : undefined }}
                />
            ),
            onFilter: (value, record) => {
                if (regex) {
                    try {
                        return new RegExp((value as string).toLowerCase()).test(
                            record['function'].toString().toLowerCase()
                        )
                    } catch (err) {
                        return true
                    }
                } else {
                    return record['function']
                        .toString()
                        .toLowerCase()
                        .includes((value as string).toLowerCase())
                }
            },

            onFilterDropdownOpenChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100)
                }
            },
        },
        {
            title: (
                <Tooltip title="the total time the function was on-CPU or part of an ancestry (functions called by the function) that was on-CPU (based on sample count)">
                    Total CPU
                </Tooltip>
            ),
            dataIndex: 'totalCPU',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a['totalCPU'] - b['totalCPU'],
            render: (text) => <>{text.toFixed(4)}%</>,
        },
        {
            title: (
                <Tooltip title="the time the function was on-CPU not including ancestry (functions called by the function)">
                    Self CPU
                </Tooltip>
            ),
            dataIndex: 'selfCPU',
            sorter: (a, b) => a['selfCPU'] - b['selfCPU'],
            render: (text) => <>{text.toFixed(4)}%</>,
        },
        {
            title: (
                <Tooltip title="The number of different services this function appeared in (one customer can have multiple services)">
                    Services
                </Tooltip>
            ),
            dataIndex: 'services',
            render: (text, record) => <> {Math.round(text)}</>,
            sorter: (a, b) => a['services'] - b['services'],
        },

        {
            title: (
                <Tooltip title="The number of different customers this function appeared in">
                    Customers
                </Tooltip>
            ),
            dataIndex: 'customers',
            render: (text, record) => <> {Math.round(text)}</>,
            sorter: (a, b) => a['customers'] - b['customers'],
        },
        {
            title: <Tooltip title="View trace">Traces</Tooltip>,
            dataIndex: 'services',
            render: (text, record) => (
                <>
                    <Tooltip title="View Function Trace">
                        <Button
                            type="primary"
                            shape="circle"
                            onClick={() => getFunctionTrace(record.function)}
                            icon={<EyeOutlined />}
                            size={'small'}
                       />
                    </Tooltip>
                    {/* <Tooltip title="Only show these services">
                <Button
                    type="primary"
                    block
                    onClick={() => filterServices(record.function)}
                >
                    {Math.round(text)}
                </Button>
            </Tooltip> */}
                </>
            ),
            sorter: (a, b) => a['services'] - b['services'],
        },
    ]

    const disabledDate = (current: any) => {
        if (!dates) {
            return false
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') >= 7
        const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 7
        return (
            !!tooEarly ||
            !!tooLate ||
            (current && current < dayjs('2023-10-27').endOf('day')) ||
            current > dayjs()
        )
    }

    const onChange = (date: any) => {
        setDates([date[0].endOf('day'), date[1].endOf('day')])
        setFunctions([])
        setArch({})
        setLanguages({})
    }

    return (
        <>
            <Card style={{ margin: '.5rem 1rem 1rem 1rem' }} size="small">
                <Space>
                    <RangePicker
                        format="MM-DD-YYYY"
                        value={dates}
                        disabledDate={disabledDate}
                        onChange={onChange}
                        changeOnBlur
                        allowClear={false}
                    />
                    Select up to 7 days of production data from Granulate (more
                    days loads slower)
                </Space>
            </Card>
            <Row gutter={16} style={{ margin: '1rem .5rem .5rem .5rem' }}>
                <Col span={12}>
                    <Card title="Language Version Breakdown" style={{}}>
                        <Spin spinning={Object.keys(languages).length == 0}>
                            <ReactECharts
                                style={{ height: '15rem' }}
                                notMerge={true}
                                option={languages}
                            />
                        </Spin>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card title="ISA Breakdown" style={{}}>
                        <Spin spinning={Object.keys(arch).length == 0}>
                            <ReactECharts
                                style={{ height: '15rem' }}
                                notMerge={true}
                                option={arch}
                            />
                        </Spin>
                    </Card>
                </Col>
            </Row>
            <Card
                title="Hotspots"
                style={{
                    margin: '.5rem 1rem 1rem 1rem',
                }}
                bodyStyle={{ padding: 0 }}
            >
                <Spin
                    spinning={functions.length == 0}
                    tip="This takes a couple minutes to load because its so big"
                >
                    <Table
                        columns={columns}
                        dataSource={functions}
                        scroll={{ x: 300 }}
                    />
                </Spin>
                <Tooltip title="Show all services">
                    <Button
                        shape="circle"
                        size="large"
                        icon={<RedoOutlined />}
                        style={{
                            position: 'fixed',
                            bottom: '2rem',
                            right: '2rem',
                            boxShadow: '2px 2px 3px #999',
                        }}
                        onClick={() => setFunctions(all)}
                    />
                </Tooltip>
            </Card>
            <ModalTrace open={open} setOpen={setOpen} data={functionTrace} />
        </>
    )
}
