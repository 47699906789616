import { API_SERVICE_URL } from '../config/service'
import api from './auth/client'

import serviceConfig from '../config/service.json'

class InsightsService {
    async getInsights(path: string = '') {
        const url = API_SERVICE_URL + serviceConfig.insightsURL
        return await api.get(url + path)
    }

    async getFunctions(func: string = '') {
        const url = API_SERVICE_URL + serviceConfig.insightsURL
        return await api.get(`${url}/functions?search=${func}`)
    }

    async getFunctionTrace(func: string = '') {
        const url = API_SERVICE_URL + serviceConfig.insightsURL
        return await api.get(`${url}/processor/functions/stack?${func}`)
    }
}

export default new InsightsService()
