import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { LoadingState } from '../models/loadingState'
import InsightsService from '../services/insightsService'

export interface IReportState {
    data: null
    loading: LoadingState
    error: null
}

const initialState: IReportState = {
    data: null,
    loading: LoadingState.Idle,
    error: null,
}

export const getInsights = createAsyncThunk(
    'insights/getInsights',
    async (path: string) => {
        return await InsightsService.getInsights(path)
    }
)

// Then, handle actions in your reducers:
const insightsSlice = createSlice({
    name: 'insights',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInsights.pending.type, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getInsights.fulfilled.type, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getInsights.rejected.type, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getFunctions = createAsyncThunk(
    'insights/getFunctions',
    async (func: string) => {
        return await InsightsService.getFunctions(func)
    }
)

// Then, handle actions in your reducers:
const functionsSlice = createSlice({
    name: 'functions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFunctions.pending.type, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFunctions.fulfilled.type, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFunctions.rejected.type, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

export const getFunctionTrace = createAsyncThunk(
    'insights/getFunctionTrace',
    async (func: string) => {
        return await InsightsService.getFunctionTrace(func)
    }
)

// Then, handle actions in your reducers:
const functionTraceSlice = createSlice({
    name: 'functionTrace',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getFunctionTrace.pending.type, (state, _) => {
                if (state.loading === LoadingState.Idle) {
                    state.loading = LoadingState.Pending
                }
            })
            .addCase(getFunctionTrace.fulfilled.type, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.data = action.payload.data
                }
            })
            .addCase(getFunctionTrace.rejected.type, (state, action: any) => {
                if (state.loading === LoadingState.Pending) {
                    state.loading = LoadingState.Idle
                    state.error = action.error
                }
            })
    },
})

// export const { setSession, clearSession } = reportSlice.actions
export const insights = insightsSlice.reducer
export const functions = functionsSlice.reducer
export const functionTrace = functionTraceSlice.reducer
