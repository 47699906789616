import {
    UserOutlined,
    BarChartOutlined,
    CommentOutlined,
    RiseOutlined,
} from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import { useState } from 'react'
import UserPanel from '../layout/userProfile'
import { BrowserRouter, Link, Navigate, Route, Routes } from 'react-router-dom'
import { HotspotContainer } from './hotspotContainer'
import { TrendContainer } from './trendContainer'

const { Content, Sider } = Layout

export const BaseContainer: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false)

    return (
        <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={() => setCollapsed(!collapsed)}
                    theme="light"
                >
                    <img
                        style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            width: '50%',
                            padding: '.3rem 0 .3rem 0',
                        }}
                        src="/Intel_energyblue_logo.png"
                        height="60"
                        alt="intel logo"
                    />
                    <Menu
                        defaultSelectedKeys={[window.location.pathname]}
                        theme="light"
                    >
                        <Menu.Item key="hotspot" icon={<BarChartOutlined />}>
                            <Link to="/hotspot">Hotspots</Link>
                        </Menu.Item>
                        <Menu.Item key="trend" icon={<RiseOutlined />}>
                            <Link to="/trend">Trends</Link>
                        </Menu.Item>
                        <Menu.Item key="feedback" icon={<CommentOutlined />}>
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxNjY0ODMyMTQzMzYifQ"
                            >
                                Feedback/Questions
                            </a>
                        </Menu.Item>
                        <Menu.Item key="user" icon={<UserOutlined />}>
                            <UserPanel />
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Content>
                    <Routes>
                        <Route index element={<Navigate to="/hotspot" />} />
                        <Route path="/hotspot" element={<HotspotContainer />} />
                        <Route path="/trend" element={<TrendContainer />} />
                        <Route
                            path="/login/callback"
                            element={<Navigate to="/hotspot" />}
                        />
                        <Route path="*" element={<Navigate to="/hotspot" />} />
                    </Routes>
                </Content>
            </Layout>
        </BrowserRouter>
    )
}
